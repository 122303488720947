<template>
    <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
          <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/robot' }">机器人管理</el-breadcrumb-item>
          <el-breadcrumb-item>新增机器人</el-breadcrumb-item>
        </el-breadcrumb>
          </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px">
          <el-form-item label="机器人名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入机器人名称(命名格式:公司+用途)"></el-input>
          </el-form-item>
          <el-form-item label="机器人地址" prop="webHook">
            <el-input v-model="form.webHook" placeholder="请输入机器人地址"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input type="textarea" v-model="form.remarks" placeholder="请输入备注" rows="5" maxlength="200" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            <el-button size="small" @click="$router.push('/robot')">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {},
        rules: {
          name:[{ required: true, message: '必填项', trigger: 'blur' }],
          webHook:[{ required: true, message: '必填项', trigger: 'blur' }],
          remarks:[{ required: true, message: '必填项', trigger: 'blur' }],
        },
        loading: false
      }
    },
    methods: {
      onSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.loading = true
            this.$ajax.post("entWechatRobotCreate", this.form).then((res) => {
              this.loading = false;
              this.$message.success('成功')
              this.$router.push('/robot')
            }).catch(err=>{
              this.loading = false
            })
          }
        })
      },
    },
    mounted() {
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .mains {
    height: 80%;
  }
  /deep/.el-loading-spinner .el-icon-loading{
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  </style>